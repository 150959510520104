﻿﻿import * as UIChange from "./UIChange.js";
import Keycloak from "keycloak_js";
import GetRealm from "./realm";
import {
  callbalancer,
  ShouldUseMegaLoadBalancer
} from "../loadbalancer/loadbalancer";
import {
  startMetricsLoginSucessSSO,
  startMetricsLoginFailSSO,
  startMetricsLoadBalanceFail,
  startMetricsLoadBalanceSucess,
  startMetricsLoadBalanceLatency,
} from "../opentelemetry/instrumentation";
import { verifyTenantStatus } from "../login-default/validations/verifyTenantStatus.js";

let urlKeyCloakProvider = "https://key.megaerp.online";
let urlKeyCloakAuth;
const realm = GetRealm();
let keycloakInstancia;

var config = ssoConfiguration || {};
var sso = {
  isSSO: config[realm]?.isSSO || false,
  realm: config[realm]?.realm || realm,
  clientId: config[realm]?.clientId || "mega-cloud",
  isLoadBalancer: config[realm]?.isLoadBalancer ?? true,
  farmUrl: config[realm]?.farmUrl || window.location.host,
  openMega: config[realm]?.openMega ?? true,
};
urlKeyCloakProvider = ssoConfiguration.authUrl;
urlKeyCloakAuth = urlKeyCloakProvider + "/auth";

function CreateKeycloak() {
  keycloakInstancia = new Keycloak({
    url: urlKeyCloakAuth,
    realm: sso.realm,
    clientId: sso.clientId,
    enablecors: true,
  });
}

export default function ExecSSO() {
  CreateKeycloak();
  keycloakInstancia
    .init({})
    .then(async function (authenticated) {
      if (!authenticated) {
        keycloakInstancia.login();
      } else {
        startMetricsLoginSucessSSO(keycloakInstancia.idTokenParsed.email);
        UIChange.loading();
        UIChange.WriteKeycloakUserName(keycloakInstancia.idTokenParsed.name);
        const keycloakLogOffUrl = `${urlKeyCloakAuth}/realms/${keycloakInstancia.realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location}&id_token_hint=${keycloakInstancia.idToken}`;
        UIChange.CreateKeyCloakLogOffButton(keycloakLogOffUrl);
        await Logon();
      }
    })
    .catch(function (e) {
      startMetricsLoginFailSSO(e, keycloakInstancia.idTokenParsed.email);
      UIChange.hideLoader();
      throw new Error(e);
    });
}

async function Logon() {
  verifyLogonValues();
  ByPassLogon();
  try {
    if (!await verifyTenantStatus("SSO", keycloakInstancia.idTokenParsed.preferred_username)) {
      UIChange.CreateWarningErroMsg("Sistema está indisponível ou sendo atualizado. Tente novamente mais tarde.","ATENÇÃO!");
      return;
    }
    const balancer = await callbalancer(true);
    LoginTsClient(balancer, keycloakInstancia);
  } catch (error) {
    console.log(error);
    UIChange.CreateErroMsg(`Algo deu de errado ao acessar o servidor: ${error}`);
  }
}

function verifyLogonValues() {
  try {
    if (
      !keycloakInstancia.idTokenParsed.mega_cloud_username ||
      !keycloakInstancia.idTokenParsed.mega_cloud_password
    ) {
      throw "Usuário com as credenciais de acesso configuradas incorretamente";
    }

    UIChange.setLoginValues(
      keycloakInstancia.idTokenParsed.mega_cloud_username,
      keycloakInstancia.idTokenParsed.mega_cloud_password
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function LoginTsClient(farmUrl, keycloakInstancia) {
  let url = `https://${farmUrl}/software/html5.html?user=${keycloakInstancia.idTokenParsed.mega_cloud_username}&pwd=${keycloakInstancia.idTokenParsed.mega_cloud_password}&domain=megacloud`;
  if (sso.openMega) {
    url = `${url}&program=C:\\\\MegaERPLauncher\\\\Mega.ERP.Auth.Launcher.App.exe&startupdir=C:\\\\MegaERPLauncher&params=${keycloakInstancia.token},${keycloakInstancia.refreshToken},Bearer,-999`;
  }
  window.open(url);
}

function ByPassLogon() {
  try {
    try {
      initHtmlPage();
    } catch (error) { }
    mainPortalInit();
    pass_original = window.pass;
    login_original = window.user;

    getOwnImplementation();

    UIChange.HideInputs();
    twoStepStatus = "disabled";
    UIChange.hideLoader();
    UIChange.showConnected(keycloakInstancia.idTokenParsed.name);
  } catch (e) {
    if (!CheckAndNotifyPopupBlocked(e)) throw new Error(e);
  }

  function CheckAndNotifyPopupBlocked(e) {
    if (e.message == "Cannot read property 'document' of null") {
      const msg =
        "Por favor configurar no navegador o desbloqueio do popup para esse site, logo em seguida faça o recarregamento do mesmo para dar sequencia no processo";
      UIChange.CreateWarningErroMsg(msg, "Popup Bloqueado");
      return true;
    }
    return false;
  }
}
