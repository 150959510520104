import GetRealm from "./realm";

function ReadConfig() {
  try {
    var config = ssoConfiguration || {};
    return config;
  } catch (e) {
    console.log(e);
  }
}

export function isHomolog(){
  return window.location.host.includes("fireq.io");
}

export default function IsSSODomain() {
  const config = ReadConfig();
  const realm = GetRealm();
  return config[realm]?.isSSO || false;
}
