export function LoadHtml(path, isAsync = false) {
    return new Promise(function(resolve, reject) {
        var styleElement = document.createElement('div');
        //styleElement.async = isAsync;
        //styleElement.href = path;
        //styleElement.rel = 'import';
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4) {
                if (this.status == 200) {

                    styleElement.innerHTML = this.responseText;
                    document.body.insertAdjacentElement('afterbegin', styleElement.firstChild);
                    resolve();
                } else {
                    reject();
                }
            }
        }
        xhttp.open("GET", path, false);
        xhttp.send();
    });

}

export function LoadScript(path, isAsync = false, target = document.head) {
    var scriptElement = document.createElement('script');
    scriptElement.async = isAsync;
    scriptElement.src = path;
    scriptElement.type = "text/javascript";
    target.insertAdjacentElement('beforeend', scriptElement);
}

export function LoadScriptBody(path, isAsync = false) {
    LoadScript(path, isAsync, document.body);
}

export function LoadCss(path, isAsync = false) {
    var styleElement = document.createElement('link');
    styleElement.async = isAsync;
    styleElement.href = path;
    styleElement.rel = 'stylesheet';
    styleElement.type = 'text/css';
    document.head.appendChild(styleElement);
}