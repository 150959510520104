export default function GetHelm() {
    let result = FindInQueryString();
    if (!result)
        result = FindInHost();
    if (!result)
        result = 'sso';

    return result;
}

function FindInQueryString() {
    var paramsBusca = new URLSearchParams(window.location.search);
    if (paramsBusca.has('realm'))
        return paramsBusca.get('realm');
    else
        return null;
}

function FindInHost() {   
    const principalDomain = GetPrincipalDomain();
    return trimEnd( window.location.host.replace(principalDomain, ''),'.');
}


function GetPrincipalDomain() {
    const parts = window.location.host.split('.');
    return parts
        .slice(-(parts.length === 4 ? 3 : 2))
        .join('.');
}

function trimEnd(content, charToremove) {
    if (content.endsWith(charToremove)) {
        content = content.slice(0, -1);
        return content;
    }
}
