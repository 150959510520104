import _ from "lodash";
import IsSSODomain from "./common";
import ExecSSO from "./ExecInit";

export default async function LoadAllDependencies() {
  if (IsSSODomain()) {
    await import("./css/style.css");
    ExecSSO();
  }
}
