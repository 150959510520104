import _ from "lodash";
import LoadAllDependencies from "./sso/InitSSO";
import AddRecaptcha from "./google/recaptcha";
import initSlideShow from "./markting/slideshow";
import loadbalanceCustomNativeFunctions from "./loadbalancer/loadbalanceCustomNativeFunctions"
import loginDefaultCustomNativeFunctions from "./login-default/loginDefaultCustomNativeFunctions"
import { initLoadBalancingTsPlus, ShouldUseMegaLoadBalancer } from "./loadbalancer/loadbalancer";

async function Init() {
  await LoadAllDependencies();
  await initSlideShow();
  AddRecaptcha();
  loadbalanceCustomNativeFunctions();
  loginDefaultCustomNativeFunctions();
}

async function TryInit() {
  let scriptElement = document.getElementById("sso-script-no-init");
  if (!scriptElement) {
    await Init();
  }
}

await TryInit();

// Login by TSPlus
if (!window.isTsPlusLoadBalanceOnly && ShouldUseMegaLoadBalancer()) {
  window.initLoadBalancing = initLoadBalancingTsPlus;
}
