
export async function verifyTenantStatus(accessType, loginIdentifier) {
    try {
        const myHeaders = new Headers();
        myHeaders.append("x-api-key", process.env.x_api_key);
        myHeaders.append("Content-Type", "application/json");
        
        const raw = JSON.stringify({
            "data": {
                type: accessType,
                identifier: loginIdentifier
            }
        });
        
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        
        const response = await fetch(`${process.env.mcm_endpoint}/checkTenantIsAvailable`, requestOptions);
        if (!response.ok) {
            throw new Error(`checkTenantIsAvailable | Status: ${response.status}`)
        }

        const json = await response.json();
        return json.result;
        
    } catch (error) {
        console.error(error);
        return true;
    }
    }