import { startMetricsLoadBalanceLatency } from "../opentelemetry/instrumentation"

export function ShouldUseMegaLoadBalancer() {
    const now = new Date();
    const weekday = now.getDay();
    const hour = now.getHours();
    const hourStartMegaLoadBalance = window.hourStartMegaLoadBalance ?? 16;

    return [0, 6].includes(weekday) || hour >= hourStartMegaLoadBalance;
}

// criar uma abstração para tratar quando está executando por dentro dos scripts do TSPlus
export async function initLoadBalancingTsPlus() {
    try {
        const result = await callbalancer(false);
        const hostname = getServerUrl(result)
        window.serversListingType = "loadbalanced";
        window.resetDropDownMenu();
        window.addServerToDropDownMenu(hostname, result, 443);
        window.disableDropDownMenu();
    } catch (error) {
        if (error === "loadbalancing-off") {
            window.serversListingType = "userassigned";
            window.checkLogin();
        }
        throw error;
    }
}

export function callbalancer(isSSO) {
    const isMgLoadbalance = !window.isTsPlusLoadBalanceOnly && ShouldUseMegaLoadBalancer();
    const farmName = window.farmName = typeof window.farmName === "undefined" ? "" : window.farmName;
    window.xhrLoadBalancing = createNewXMLHttpRequest();
    const urlString = isMgLoadbalance ? new URL(`https://tslb.megaerp.online/best-server/farm?farm_name=${farmName}`) : new URL(`https://${document.location.host}/cgi-bin/hb.exe?action=lb`);
    const params = isMgLoadbalance ? null : `action=lb&t=${new Date().getTime()}&d=${document.getElementById("Editbox3").value}&l=${(page_configuration["is_webcredentials"] ? "@" : "")}${document.getElementById("Editbox1").value}`;
    window.xhrLoadBalancing.open("GET", urlString, true);
    window.xhrLoadBalancing.send(params);
    return new Promise((resolve, reject) => {
        window.xhrLoadBalancing.onreadystatechange = isSSO ? onReadyStateChangeSSO(resolve, reject) : onReadyStateChangeTsPlus(resolve, reject);
    });
}

function createNewXMLHttpRequest() {
    try {
        return new XMLHttpRequest();
    } catch (e) { }
    try {
        return new ActiveXObject('MSXML2.XMLHTTP');
    } catch (e) { }
    return new ActiveXObject('Microsoft.XMLHTTP');
}

function getServerUrl(url) {
    const urlSplited = url.split("~~");
    return urlSplited[urlSplited.length - 1];
}

// Metodo chamado quando não é SSO e o LB for Mega
function onReadyStateChangeTsPlus(resolve, reject) {
    return () => {
        if (window.xhrLoadBalancing.readyState == 4) {
            if (window.xhrLoadBalancing.status == 200) {
                window.xhrLoadBalancing.onload = () => {
                    const entries = performance.getEntriesByType("resource");
                    const filteredEntries = entries.filter(function (entry) {
                        return entry.initiatorType == "xmlhttprequest" && entry.nextHopProtocol == 'http/1.1';
                    });
                    const lastEntry = filteredEntries[filteredEntries.length - 1] || {};
                    startMetricsLoadBalanceLatency(window.xhrLoadBalancing.status, lastEntry.duration, "mega-lb");
                }
                let balancer = JSON.parse(window.xhrLoadBalancing.responseText);
                resolve(`${document.location.host}/~~${balancer.hostname}`);
            }
            reject(window.xhrLoadBalancing.responseText);
        }
    };
}

function onLoadRequestLatencyMetric() {
    const entries = performance.getEntriesByType("resource");
    const filteredEntries = entries.filter(function (entry) {
        return entry.initiatorType == "xmlhttprequest" && entry.nextHopProtocol == 'http/1.1';
    });
    const lastEntry = filteredEntries[filteredEntries.length - 1];
    startMetricsLoadBalanceLatency(window.xhrLoadBalancing.status, lastEntry.duration, "mega-lb");
}


// Metodo chamado quando é SSO indiferente do LB
function onReadyStateChangeSSO(resolve, reject) {
    return () => {
        if (window.xhrLoadBalancing.readyState === 4 && window.xhrLoadBalancing.status === 200) {
            const isJson = window.xhrLoadBalancing.getResponseHeader("content-type").includes("json");
            let balancer = '';
            if (isJson) {
                window.xhrLoadBalancing.onload = onLoadRequestLatencyMetric;
                const json = JSON.parse(window.xhrLoadBalancing.responseText);
                balancer = `${document.location.host}/~~${json.hostname}`;
            } else {
                balancer = window.xhrLoadBalancing.responseText.split('|')[2];
            }
            resolve(balancer);
        } else if (window.xhrLoadBalancing.readyState === 4 && window.xhrLoadBalancingxhr.status != 200) {
            reject(window.xhrLoadBalancing.responseText);
        }
    };
};

